import React from "react";
import "./ContactUs.css";
import NavBar from "../../header/navbar/NavBar";
import ContactForm from "./ContactForm";
import Footer from "../../footer/Footer";


const ContactUs = () => {
  return (
    <div className="main">
      <div className="navcover"></div>
      <div className="contact-us">
        <NavBar />
        <ContactForm />
        <div className="contact-map"></div>
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
