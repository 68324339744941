import React from "react";
import "./TruntedCountry.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const TrustedCountry = () => {
  return (
    <div className="trust-container">
      <div className="child-trust"></div>
      <div className="child-trust">
        <h3>
          <span className="figures">10+</span> <br /> Countries Worldwide
        </h3>
        <p className="child-trust-p">
          To succeed, every software solution must be deeply integrated into the existing tech environment
        </p>
        {/* <a href="#some-section" className="trust-learn">
          <FontAwesomeIcon
            icon={faArrowRight}
            size="lg"
            className="dis-arrow"
          />
          LEARN MORE
        </a> */}
      </div>
      <div className="child-trust"></div>
      <div className="child-trust">
        <h3>
          <span className="figures">15k</span> <br /> Happy Customers
        </h3>
        <p className="child-trust-p">
          To succeed, every software solution must be deeply integrated into the existing tech environment
        </p>
        {/* <a href="some-section" className="trust-learn">
          <FontAwesomeIcon
            icon={faArrowRight}
            size="lg"
            className="dis-arrow"
          />
          LEARN MORE
        </a> */}
      </div>
    </div>
  );
};

export default TrustedCountry;
