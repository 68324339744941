import React from "react";
import "./WhyChooseUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import NavBar from "../header/navbar/NavBar";
import ServiceCard from "../service-card/ServiceCard";
import Rate from "../../assets/five-start-bg.png";
import Footer from "../footer/Footer";

import Image1 from "../../assets/Software Development.png";
import Image2 from "../../assets/UI UX design.png";
import Image3 from "../../assets/Consultation.png";
import Image4 from "../../assets/servers.png";
import Image5 from "../../assets/Mobile and Web.png";
import Image6 from "../../assets/Automation.png";
import ClientImage1 from "../../assets/user_1.png";
import ClientImage2 from "../../assets/user_1.png";


const WhyChooseUs = () => {
  const serviceData = [
    {
      img: Image1,
      alt: "Web",
      title: "Software Development",
      description:
        "Creating secure, responsive web and mobile-friendly applications tailored specially for your business.",
      href: "/software"
    },
    {
      img: Image2,
      alt: "UI",
      title: "UI UX & Graphic Design",
      description:
        "Enhance your online visibility through our UI/UX design services, merging innovation and creativity to create designs that align with your brand and elevate user engagement.",
      href: "/uiux"
    },
    {
      img: Image3,
      alt: "Consultancy",
      title: "Web Consultancy",
      description:
        "We provide strategic insights and tailored guidance for startups and established businesses, empowering you to make informed decisions and achieve sustained digital growth.",
      href: "/web"
    },
    {
      img: Image4,
      alt: "Server",
      title: "NetWorking & Windows Server",
      description:
        "We specialize in crafting secure and efficient IT infrastructures, offering seamless network configurations and robust server solutions tailored to your unique requirements.",
      href: "/network"
    },
    {
      img: Image5,
      alt: "Mobile",
      title: "Mobile App",
      description:
        "Be it small businesses or large Enterprises; B2B or B2C, We design & build optimized &  results-driven custom mobile applications using cutting edge technologies for all mobile platforms.",
      href: "/mobile"
    },
    {
      img: Image6,
      alt: "Web",
      title: "Automation & Robotics",
      description:
        "Step into the future of business excellence with our Automation & Robotics service. Explore cutting-edge innovation as we provide bespoke solutions, revolutionizing your processes and boosting operational efficiency.",
      href: "#"
    },
  ];

  return (
    <div>
      <NavBar />
      <div className="choose-content">
        <div className="choose-title">
          <h1>Why Choose US</h1>
          <h6>
            {" "}
            <span className="choose-style">Home</span>{" "}
            <span className="choose-icon">
              <FontAwesomeIcon icon={faCaretRight} size="1x" />
            </span>{" "}
            Why Choose US{" "}
          </h6>
        </div>
        
      </div>
      <div className="choose-heading">
        <h1>Preparing for your success, <br />
          we provide <span style={{ color: 'rgb(8, 106, 216)' }}>truly prominent IT solutions.</span>
        </h1>
        <p>
          Choosing the right software development company in order to have your dreams achieved and success for your internet product <br />
          can be a difficult task. Here we make sure that you have made the best decision for your project to start with and shape the <br />
          product as you imagine. <br />
          Because we make it simple. <br />
          Clean code implementationwith powerful testing.
        </p>
      </div>
      <div className="choose-us">
        <h1>
          Why Choose <span style={{ color: "#0d6efd" }}>Us.</span>
        </h1>
        <div className="choose-description">
          <div className="choose-description-left">
            <h1>
              Quality Digital <br />
              Products.
              <br />
              Delivered
            </h1>
          </div>
          <div className="choose-description-right">
            <h4>Client First</h4>
            <p>
              For any business, Clients are always at the core. Being a leading
              developer of web and mobile applications, our concept goes beyond
              our direct customers.We always start from where you are with your
              ideas and we think from the viewpoints of your end users, their
              areas of pain, and formulate a solution that solves key issues to
              help your business.
            </p>

            <h4>Effective Synergy</h4>
            <p>
              Communication is to hear what isn't being said. We appear at times
              to over-communicate. While it sounds overwhelming, it works
              wonders for our clients and our company ultimately. With
              communication alone we solve real-world problems. Our Development
              team remains in contact with customers through channels and media
              that allow performance, transparency and accuracy, feasible with
              all time zones across the World.
            </p>

            <h4>On Time Delivery</h4>
            <p>We do not over promise we over-deliver our promise. </p>
            <p>
              We have a great collection of internal frameworks with
              best-in-class infrastructure with a decade of experience in the IT
              software development industry that helps us to deliver solutions
              with superior quality at all times. We have learned how to avoid
              mistakes and repeat success all these years. We don't brag about
              consistency alone. In time, we describe and produce it. Our
              Products are here to notify the same.
            </p>

            <h4>Strong Expertise</h4>
            <p>
              With our core field of expertise being Ruby on Rails, Angular,
              React, our coding experts remain on the cutting edge of the newest
              and most successful technology trends and popular applications on
              the market. So you can expect a greater level of ownership,
              commitments & deliveries in less time with higher quality while
              following all the best practices for the development.
            </p>

            <h4>Total Ownership & Feedback Based</h4>
            <p>
              We do not follow the criteria or requirements just like that.
              Right from the beginning we have a direct influence on the
              project. We take spontaneous feedback from our clients, their
              customers, and other stakeholders that have an impact on the
              product. And we take your product and its feedback seriously. With
              this we make sure that we all are heading towards the right
              direction. Which saves really everything that matters.
            </p>
          </div>
        </div>
      </div>
      <div className="choose-card">
        {serviceData.map((data, index) => (
          <ServiceCard
            key={index}
            img={data.img}
            alt={data.alt}
            title={data.title}
            description={data.description}
            href={data.href}
          />
        ))}
      </div>
      <div className="testimonial">
        <div className="testimonial-text">
          <h1>Testimonial</h1>
          <h3>
            What people praise about{" "}
            <span className="testimonial-title">MassiTec?</span>
          </h3>
        </div>

        <div className="testimonal-card">
          <div class="map-slider">
            <div class="map-child">
              <div className="map-header">
                <img src={ClientImage1} alt="Client1" width={65} />
                <div className="map-header-title">
                  <img src={Rate} alt="Rating" width={100} height={40} className="map-header-image" />
                  <h6>Jack</h6>
                </div>
              </div>
              <p>
                Excellent and quality work, and the entire team consistently goes above and beyond to assist our company. Whatever the problem is, whether it's technological or operational, they will find a way to solve it.
              </p>
            </div>
            <div class="map-child">
              <div className="map-header">
                <img src={ClientImage2} alt="Client2" width={65} />
                <div className="map-header-title">
                  <img src={Rate} alt="Rating" width={100} height={40} className="map-header-image" />
                  <h6>Emmanuel</h6>
                </div>
              </div>
              <p>
                MassiTec group has been the most successful organization we've ever partnered with. Within our model, the team can design features and experiences, with strong emphasis on pace to market.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default WhyChooseUs;
