import React from 'react'
import './UIUXService.css'
import NavBar from '../header/navbar/NavBar'
import Contact from '../contact/Contact'
import Footer from '../footer/Footer'
import { FaDesktop, FaMobileAlt, FaPencilRuler, FaChartBar, FaPaintBrush } from 'react-icons/fa';


import UI1 from '../../assets/ui ux 1.png'
import UI2 from '../../assets/ui ux 2.png'
import UI3 from '../../assets/ui ux 3.png'
import UI4 from '../../assets/ui ux 4.png'
import UI5 from '../../assets/ui ux 5.png'
import UI6 from '../../assets/ui ux 6.png'
import UIService1 from '../../assets/ui_service_1.png'
import UIService2 from '../../assets/ui_service_2.png'
import UIService3 from '../../assets/ui_service_3.png'
import UIService4 from '../../assets/ui_service_4.png'
import UIService5 from '../../assets/responsive-design.png'
import DesignSteps from '../../assets/steps-of-ui-ux-design-process.png'

const UIUXService = () => {
    const UIImages = [
        UI1, UI2, UI3, UI4, UI5, UI6
    ]
    const UIServices = [
        UIService1, UIService2, UIService3, UIService4, UIService5
    ]

    const services = [
        {
            icon: <FaDesktop />,
            title: 'User Interface (UI) Design',
            description: 'We create visually appealing and user-friendly interfaces that enhance the overall experience of your digital products. Every button, menu, and interaction is designed with the user in mind, ensuring ease of use and satisfaction.',
        },
        {
            icon: <FaMobileAlt />,
            title: 'User Experience (UX) Design',
            description: 'Our UX design focuses on optimizing the user journey. We analyze user behavior, conduct testing, and refine designs to ensure your product is intuitive, efficient, and enjoyable to use.',
        },
        {
            icon: <FaPencilRuler />,
            title: 'Graphic Design',
            description: 'From logos to marketing materials, our graphic design services cover all aspects of your brand’s visual identity. We create eye-catching designs that resonate with your audience and leave a lasting impression.',
        },
        {
            icon: <FaChartBar />,
            title: 'Prototyping and Wireframing',
            description: 'Before any development begins, we create detailed prototypes and wireframes, allowing you to visualize the final product and make informed decisions early in the process.',
        },
        {
            icon: <FaPaintBrush />,
            title: 'Responsive Design',
            description: 'We ensure that your digital products look and function perfectly on any device, from desktops to smartphones, providing a consistent experience across all platforms.',
        },
    ];

    return (
        <div>
            <div className="design-content">
                <div className="design-title">
                    <h1>UI / UX Design</h1>
                </div>
                <NavBar />
            </div>

            <div className='design-home'>
                <div className='design-home-title'>
                    <h2 className='title'>Good design is good business</h2>
                    <h4 style={{ marginBottom: '3rem' }}>- Thomas Watson Jr</h4>
                </div>
            </div>

            <div className='design-home-desc mt-5 '>
                <div>
                    <h2 className='title'>Create Exceptional Digital Experiences with Expert Design Services</h2>
                    <div style={{ textAlign: 'justify', width: '80%', margin: '0 auto' }}>
                        <p className='mt-3'>
                            First impressions are often made in the blink of an eye. At Massitec, we understand the power of great design, it’s more than being creative, it’s about creating intuitive, memorable experiences that connect with your audience on a deeper level. Our UI/UX and graphic design services are tailored to elevate your brand and ensure that every interaction with your digital products is seamless and enjoyable.
                        </p>
                        <p>We at MassiTec Technologies believe that great user interface design is the secret to any company or organization's success, explore more on what we can do for you.</p>

                    </div>
                </div>
                <section className="business-model">
                    <h2 className="title">Our Business Model</h2>
                    <div className="content-container">
                        <div className="map-container">
                            <img src={DesignSteps} alt="Design Steps" className="map-image" />
                        </div>

                        <div className="description-container">
                            <div className="description-box">
                                <h3>Product Definition</h3>
                                <p>
                                    We start by understanding your business, your audience, and your goals. This foundation allows us to create designs that truly resonate with your users.
                                </p>
                                <div className="step-number">1</div>
                            </div>
                            <div className="description-box">
                                <h3>Research</h3>
                                <p>
                                    Our team conducts in-depth research to identify user needs, preferences, and pain points. This data-driven approach ensures that our designs are based on real insights.
                                </p>
                                <div className="step-number">2</div>
                            </div>
                            <div className="description-box">
                                <h3>Analysis</h3>
                                <p>
                                    We analyze the research findings to develop user personas, user journeys, and wireframes. This step helps us create a clear roadmap for the design process.
                                </p>
                                <div className="step-number">3</div>
                            </div>
                            <div className="description-box">
                                <h3>Design</h3>
                                <p>
                                    Our designers bring the wireframes to life, creating visually stunning interfaces that are both beautiful and functional. We focus on every detail to ensure a seamless user experience.
                                </p>
                                <div className="step-number">4</div>
                            </div>
                            <div className="description-box">
                                <h3>Testing and Validation</h3>
                                <p>
                                    Before launch, we conduct extensive testing to ensure that the design meets the highest standards of quality and usability. User feedback is incorporated to refine the design further.
                                </p>
                                <div className="step-number">5</div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className='design-services-ui'>
                <h1 className='title'>Our Design Services</h1>
                <div className='design-services-ui-content'>
                    <div className='design-services-ui-cards'>
                        {services.map((service, index) => (
                            <div key={index} className='service-card'>
                                <div className='service-card-icon'>
                                    {service.icon}
                                </div>
                                <h3>{service.title}</h3>
                                <p>{service.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='design-services-ui-contact h-100'>
                <Contact h3={"Want to create a digital experience that captivates your audience?"}
                    h5={"Let’s design something extraordinary together!"}
                />
            </div>
            {/* <Requirement /> */}
            <Footer />
        </div>
    )
}

export default UIUXService