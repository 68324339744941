import React, { useState } from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import Button from "react-bootstrap/Button";
import { SocialIcon } from 'react-social-icons'

const Footer = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid, Please provide a valid email");
    } else {
      setError(null);
    }

    setEmail(event.target.value);
  };

  return (
    <div className="footer-main">
      <div className="footer-content">
        <div>
          <h5>Services</h5>
          <p><a href="/software">Software Development</a></p>
          <p><a href="/uiux">UI UX & Graphic Design</a></p>
          <p><a href="/web">Web Consultancy & Hosting</a></p>
          <p><a href="/network">Network & Windows Server</a></p>
          <p><a href="/mobile">Mobile App</a></p>
        </div>
        <div className="footer-company">
          <h5>Company</h5>
          <p><a href="/about">About Us</a></p>
          <p><a href="/why-us">Why Choose Us</a></p>
          <p><a href="/work-methodology">Work Methodology</a></p>
        </div>
        <div className="footer-sub-main">
          <h5>Subscribe</h5>
          <div className="subscribe-input">
            <p>Follow our newsletter to stay updated about agency</p>
            <div className="input-button-container input-error-container">
              <input
                // type="email"
                value={email}
                id="subscribe-email"
                className="footer-subscibe"
                placeholder="Enter Email"
                onChange={handleChange}
              />
              {error && <h2 className="error-message">{error}</h2>}
              <Button>
                <FontAwesomeIcon icon={faPaperPlane} size="lg" />
              </Button>
            </div>
            <aside>
              <SocialIcon url="https://twitter.com/" href='https://x.com/Massitecgh' target="_blank" style={{ marginRight: '8px', height: '35px', width: '35px'}}/>
              <SocialIcon url="https://www.facebook.com/" href='https://www.facebook.com/massitecgroup' target="_blank" style={{ marginRight: '8px', height: '35px', width: '35px' }} />
              <SocialIcon url="https://www.instagram.com/" href='https://www.instagram.com/massitecgh/' target="_blank" style={{ marginRight: '8px', height: '35px', width: '35px' }} />
              <SocialIcon url="https://www.linkedin.com/" href='https://www.linkedin.com/company/massitec-group/?viewAsMember=true' target="_blank" style={{ marginRight: '10px', height: '35px', width: '35px' }} />
              <SocialIcon url="https://www.youtube.com/" href='#someLinkl' target="_blank" style={{ height: '35px', width: '35px'}} />
            </aside>
          </div>
        </div>
      </div>
      <div className="footer-media">
        <p>Copyright @2023 massitec. All right reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
