import React from "react";
import "./ServiceCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const ServiceCard = ({ img, alt, title, description, href }) => {
  return (
    <div className="card-main">
      <div className="card-content">
        <div className="image-side">
          <img src={img} alt={alt} width={60} height={60} />
        </div>

        <div className="text-content">
          <h4 style={{color: 'rgb(8, 106, 216)'}}>{title}</h4>
          <p>{description}</p>
          <a href={href} className="discover-service">
            Discover now
            <FontAwesomeIcon
              icon={faArrowRight}
              size="lg"
              className="dis-arrow"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
