import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ContactUs from "./components/contact/contactus/ContactUs";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import AboutUs from "./components/about/AboutUs";
import WhyChooseUs from "./components/choose-us/WhyChooseUs";
import WorkMethodology from "./components/work-methodology/WorkMethodology";
import SoftwareDevService from "./components/software-dev-service/SoftwareDevService";
import MobileService from "./components/mobile-service/MobileService";
import UIUXService from "./components/uiux-service/UIUXService";
import WebConsultancy from "./components/web-service/WebConsultancy";
import NetworkService from "./components/network-service/NetworkService";
import Error from "./components/404/Error";
import Blog from "./components/blog/Blog";
import SingleBlog from "./components/blog/SingleBlog";
import HighPerformantWebApp from "./components/blog/HighPerformantWebApp";
import ElevateUX from "./components/blog/ElevateUX";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/why-us" element={<WhyChooseUs />} />
          <Route path="/work-methodology" element={<WorkMethodology />} />
          <Route path="/software" element={<SoftwareDevService />} />
          <Route path="/mobile" element={<MobileService />} />
          <Route path="/uiux" element={<UIUXService />} />
          <Route path="/web" element={<WebConsultancy />} />
          <Route path="/network" element={<NetworkService />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/ai-health-care" element={<SingleBlog />} />
          <Route path="/high-performant-website" element={<HighPerformantWebApp />} />
          <Route path="/elevate-ux" element={<ElevateUX />} />
          <Route path="*" exact={true} element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
