import React from 'react'
import './WebConsultancy.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import NavBar from '../header/navbar/NavBar'
import Footer from '../footer/Footer'
import { FaDesktop, FaCogs, FaShoppingCart, FaTachometerAlt, FaCloud, FaTools } from 'react-icons/fa';

import Emphathize from '../../assets/Empathize.png'
import Define from '../../assets/Define.png'
import Ideation from '../../assets/Ideation.png'
import prototype from '../../assets/Prototype.png'
import Implement from '../../assets/Implement.png'
import Contact from '../contact/Contact'
import WebServiceDesc from './web-service-desc/WebServiceDesc'


const WebConsultancy = () => {
    const serviceImages = [
        Emphathize, Define, Ideation, prototype, Implement
    ]

    const services = [
        {
            title: "Web Application Development",
            icon: <FaDesktop />,
            description:
                "Development of tailored web solutions that meet specific business needs, ensuring a robust, scalable, and user-friendly experience.",
        },
        {
            title: "Content Management System",
            icon: <FaCogs />,
            description:
                "Creation of customized CMS platforms like WordPress, Joomla, or bespoke solutions for easy content management and updates.",
        },
        {
            title: "E-Commerce Development",
            icon: <FaShoppingCart />,
            description:
                "Building feature-rich, secure, and scalable online stores with custom functionalities that provide a seamless shopping experience.",
        },
        {
            title: "Website Performance Optimization",
            icon: <FaTachometerAlt />,
            description:
                "Improving website speed, responsiveness, and user experience by optimizing loading times, content delivery, and server performance.",
        },
        {
            title: "Web Hosting",
            icon: <FaCloud />,
            description:
                "Providing reliable and secure web hosting solutions, along with cloud-based services for scalability and performance.",
        },
        {
            title: "Web Application Maintenance",
            icon: <FaTools />,
            description:
                "Offering ongoing support, updates, and maintenance to ensure that your web applications run smoothly and stay secure.",
        },
    ];
    return (
        <div>
            <div className="web-content">
                <div className="web-title">
                    <h1>Web Consultancy & Hosting</h1>
                    <h6>
                        {" "}
                        <span className="design-style">Home</span>{" "}
                        <span className="choose-icon">
                            <FontAwesomeIcon icon={faCaretRight} size="1x" />
                        </span>{" "}
                        Web Consultancy & Hosting{" "}
                    </h6>
                </div>
                <NavBar />
            </div>
            <div className='web-home-desc'>
                <div style={{ width: '80%', margin: '0 auto', marginBottom: '8rem' }}>
                    <h2 className='web-title-heading'>Elevate Your Online Presence with Expert Web Consultancy Services</h2>
                    <p style={{ textAlign: 'justify' }}>
                        Your website is often the first interaction potential customers have with your business. At Massitec, we recognize the critical role of a compelling online presence. Our web consultancy services are tailored to help you build a website that not only captivates but also drives traffic, engages visitors, and converts them into loyal customers. Whether you're starting from scratch or aiming to enhance an existing site, our team of experts is dedicated to guiding you through every step of the process.
                    </p>
                </div>
                <div className='web-tools-section'>
                    <div className='web-tools-left'>
                        <h3 className="section-title" style={{textAlign: 'left'}}>Digital Strategy & <br /> Consultation </h3>
                        <p className='web-tools-left-content'>
                            At MassiTec, we offer comprehensive digital strategy and consultation services tailored to your business needs. Whether you require full-time, part-time, or hourly support for software projects, we provide the flexibility to match various project requirements, regardless of company size.
                        </p>
                    </div>
                    <div className='web-tools-description'>
                        <p>
                            Our team is dedicated to helping you envision, execute, and sustain your digital future. We leverage the latest technology to guide you from strategy creation to successful execution, ensuring you are well-prepared to evolve and transform your business. By working together, we can address complex challenges and drive innovation.
                        </p>
                        <p>
                            As digital preferences continuously evolve, customers demand more than just a digital brand presence. Our digital marketing solutions are designed to maximize the value of your data across all platforms. We provide strategic marketing management that helps companies understand and address customer needs, enhance engagement, and build personalized relationships.
                        </p>
                        <p>
                            MassiTec stands out as a leading Digital Marketing Management Services firm. Our strategic approach allows us to offer insights and optimization recommendations based on consumer data and market analysis. We help you achieve a better product-market fit and generate new value, ultimately contributing to your growth.
                        </p>
                    </div>
                </div>
            </div>
            <div className='web-home-app-architecture'>
                <h3 className="section-title">
                    Custom Web & Application Development Services
                </h3>
                <div className='web-home-app-architecture-title'>
                    <p>
                        Our personalized software solutions are designed to resolve specific business challenges and produce meaningful outcomes, from easy customizations to full-cycle software growth. We are a leading web application development company for custom web solutions. We are experts in Web development, Bespoke CMS, CRM, ERP Development, & Responsive Web Apps.
                    </p>
                </div>

                <div className="consultancy-section">
                    <h2 className="section-title">Our Web Consultancy</h2>
                    <div className="services-grid">
                        {services.map((service, index) => (
                            <div className="service-card" key={index}>
                                <div className="icon-container">{service.icon}</div>
                                <h4 className="service-title">{service.title}</h4>
                                <p className="service-description">{service.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='web-home-contact'>
                <Contact h3={"Ready to elevate your online presence? Contact us now!"}
                    h5={"We Consultancy and Development "} />
            </div>
            <Footer />
        </div>
    )
}

export default WebConsultancy