import React from 'react'
import './MobileService.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import Footer from '../footer/Footer'
import Contact from '../contact/Contact'

import NavBar from '../header/navbar/NavBar'
import Mobile from '../../assets/app-watch.png'
import Mobile1 from '../../assets/mobile1.png'
import Mobile2 from '../../assets/mobile2.png'
import Mobile4 from '../../assets/mobile4.png'
import MobileContent from './mobile-content/MobileContent'
import AppleW from '../../assets/apple-w.png'



const MobileService = () => {
    return (
        <div>
            <div className="mobile-content">
                <div className="mobile-title">
                    <h1>Mobile App</h1>
                    <h6>
                        {" "}
                        <span className="mobile-style">Home</span>{" "}
                        <span className="choose-icon">
                            <FontAwesomeIcon icon={faCaretRight} size="1x" />
                        </span>{" "}
                        Mobile App{" "}
                    </h6>
                </div>
                <NavBar />
            </div>
            <div>
                <h1 className='mobile-heading'>Mobile Apps Development</h1>
                <h3 className='mobile-heading text-center mobile-heading-custom'>Connect with Your Customers Anytime, Anywhere with Custom Mobile Apps</h3>
                <p className='mobile-heading-desc' style={{ width: '70%', margin: '0 auto', textAlign: 'justify' }}>
                    In a world where mobile devices are ubiquitous, having a robust mobile presence is no longer optional—it’s essential. At Massitec, we specialize in creating custom mobile apps that put your business right in the hands of your customers. Whether you’re looking to build a brand-new app or enhance an existing one, our team is here to deliver mobile solutions that are user-friendly, secure, and aligned with your business goals.
                </p>
            </div>
            <div className='mobile-software'>
                <div className='mobile-apple'>
                    <img src={AppleW} alt='AppleW' width={550} />
                </div>
                <div className='apple-desc'>
                    <h1 className='mobile-heading'>Your Partner for <br /> Software Innovation</h1>
                    <p>
                        MassiTec is the partner of choice for many of the world's leading enterprises, SMEs and technology challengers. We help businesses elevate their value through custom software development, product design, QA and consultancy services.
                    </p>
                    <p>
                        Our team is dedicated to maintaining and modernizing your IT infrastructure while addressing a wide range of infrastructure-specific challenges your business may encounter.
                    </p>
                </div>
            </div>

            <div className='mobile-main'>
                <div>
                    <h1 className='mobile-heading'>Our Mobile App Services</h1>
                    <h3 className='mobile-heading text-center'>Connect with Your Customers Anytime, Anywhere with Custom Mobile Apps</h3>

                </div>
                <div className='mobile-description'>
                    <div className='middle-phone'>
                        <img src={Mobile} alt='mobile' height={450} />
                    </div>
                    <div>
                        <MobileContent
                            title={'iOS App Development'}
                            description={"We design and develop high-performance iOS apps that leverage the full capabilities of Apple’s platform. From iPhones to iPads, we ensure your app looks and works perfectly on every device."}
                            Image={Mobile1}
                        />
                        <MobileContent
                            title={'Android App Development'}
                            description={"Our Android app development services cover the full range of devices, from smartphones to tablets. We create apps that are fast, secure, and optimized for the Android platform."}
                            Image={Mobile2}
                        />
                        <MobileContent
                            title={'Cross-Platform App Development'}
                            description={"For businesses that need to reach users on both iOS and Android, we offer cross-platform solutions that deliver a consistent experience across all devices. We use frameworks like React Native and Flutter to create apps that feel native on every platform."}
                            Image={Mobile4}
                        />
                    </div>
                </div>
            </div>
            <div className='design-services-ui-contact h-100'>
                <Contact h3={"Ready to connect with your customers on a deeper level?"}
                    h5={"Let’s build a mobile app that drives your business forward—get in touch with us today!"}
                />
            </div>
            <Footer />
        </div>
    )
}

export default MobileService