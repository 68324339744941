import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "./ContactForm.css";
import "react-international-phone/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import emailjs from '@emailjs/browser';
import { notification } from "antd";

const ContactForm = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_y486ehu', 'template_jal05g6', form.current, 'uHHgy6GEFXOnPjvha')
      .then((result) => {
          notification.success({
            message: 'Success',
            description:
              'Email sent successfully',
          });
      }, (error) => {
          notification.error({
            message: 'Error',
            description:
              'Email not sent',
          });
      });
  };


  return (
    <div>
      <div className="contact-title">
        <h1>Contacts</h1>
        <h6> <span className="contact-style">Home</span> <span className="contact-icon"><FontAwesomeIcon icon={faCaretRight} size="1x" /></span> Contact </h6>
      </div>

      <div className="contact-form">
        <div className="contact-text">
          <span className="vertical-line"></span>
          <h3>
            Let us build your <br />
            <span className="build-dream">dream digital</span> product <br />{" "}
            together. <br />
            <span className="action">
              Take a minute to fill this form and we will reach you out in less
              than 24 Hours.
            </span>
          </h3>
        </div>
        <div className="contact-form-inner">
          <Form ref={form} onSubmit={sendEmail}>
            <Row className="mb-6">
              <Form.Group
                as={Col}
                md="12"
                className="mb-3"
                controlId="validationCustom01"
              >
                <Form.Control
                  required
                  type="text"
                  placeholder="Name"
                  name="from_name"
                  
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Control
                  required
                  type="text"
                  placeholder="Email"
                  name="from_email"
                  // pattern="[A-Za-z]+"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12">
                <Form.Select
                 name="from_select_a_Service"
                  aria-label="Select a Service"
                  
                >
                  <option>Select a Service</option>
                  <option >Website</option>
                  <option>Web Application</option>
                  <option>Mobile App Development</option>
                  <option>Consulting - Product & Scaling</option>
                  <option>UI/UX Design & Development</option>
                  <option>Custom Development</option>
                  <option>Others</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="12">
                <Form.Select
                  aria-label="How can you start?"
                  name="from_how_can_you_start"
                 
                >
                  <option>How can you start?</option>
                  <option>Right Away</option>
                  <option>In few Weeks</option>
                  <option>In few Months</option>
                  <option>Not Sure</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="12">
                <Form.Select
                  aria-label="Requirement"
                  name="from_requirement"
                  
                >
                  <option>Requirement</option>
                  <option>Hire a Developer</option>
                  <option>Hire a Dedicated team</option>
                  <option>Complete New Project</option>
                  <option>Existing Project</option>
                </Form.Select>
              </Form.Group>
            </Row>
            {/* <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom02">
                <Form.Group as={Col} md="12">
                    <PhoneInput
                      defaultCountry={"us"}
                      placeholder="Phone"
                      name="from_phone"
                      required
                    />
                </Form.Group>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row> */}
            <Row className="mb-6">
              <Form.Group
                as={Col}
                md="12"
                className="mb-3"
                controlId="validationCustom01"
              >
                <Form.Control
                  required
                  type="number"
                  placeholder="Phone Number"
                  name="from_phone"
                  
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Message"
                name="message"
                required
              />
            </Form.Group>

            <p className="contact-p">
              100% Secure,all the projects are secured by NDA, IP & Copyrights
              <br />
              <span>
                The site is protected by reCAPTCHA and Google Privacy Policy &
                Terms of Service apply.
              </span>
            </p>
            <Button className="form-submit" type="submit">
              Get Started
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
