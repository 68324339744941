import React from 'react'
import './Requirement.css'
import Image1 from '../../assets/Requirement.png'
import Image2 from '../../assets/Designing.png'
import Image3 from '../../assets/Architecture.png'
import Image4 from '../../assets/Develpment.png'
import Image5 from '../../assets/Stagging Realease.png'
import Image6 from '../../assets/CI CD Testing.png'
import Image7 from '../../assets/Quality Assurance.png'
import Image8 from '../../assets/monitoring.png'
import Image9 from '../../assets/Security Monitoring.png'
import Image10 from '../../assets/Support.png'
import Description from './Description/Description'


const Requirement = () => {
    const ImageList = [
        Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10
    ]
    return (
        <div className='requirement-main'>
            <div className='requirment-container'>
                <div className='requirement-left'>
                    <div className='requirement-left-img'>
                        {ImageList.map((image, index) => (
                            <div key={index} className='image-container'>
                                <img src={image} alt={"Requirement"} width={300} className='requirement-img' />
                                {index !== ImageList.length - 1 && <span className='requirement-line' />}
                            </div>
                        ))}
                    </div>
                </div>

                <div className='requirement-right'>
                    <Description
                        title={"Requirement Analysis"}
                        description={"We compile all the data obtained from our customers and carry out a complete analysis to find possibilities in existing business processes, to take into account the current processes and technologies of our customers and how they can interact with future design. And how we can deliver faster thereby minimizing the lead time to market."}
                        goal={"Goal"}
                        goalDescrition={"User Story Development and User Journey mapping"}
                    />
                    <Description
                        title={"Designing"}
                        description={"We Build a holistic and appropriate solution delivery architecture, prioritizing activities into three classes Vital, Relevant & Optional on the nature of business requirements and current investments. And then design the workflows, mock-ups and high-fidelity prototypes to support the need and visualise the application and make sure it is as per the idea of our customer."}
                        goal={"Goal"}
                        goalDescrition={"Wireframing, Designing, Mock-ups, User story enablement handoffs for development."}
                    />
                    <Description
                        title={"Architecture"}
                        description={"Our process of software design and architecture is usually separated into four views: conceptual, module, execution, and code. We architect the solution as per the requirements & design so that the application supports all the requirements and is easy to scale. We provide a project plan focused on compliance guidelines and best practices in the industry, using change management to ensure adequate management, efficient communication, and contingency planning."}
                        goal={"Goal"}
                        goalDescrition={"Define user stories technically. Finalise the technical requirements"}
                    />
                    <Description
                        title={"Development"}
                        description={"Develop a project plan with comprehensive implementation criteria, consideration of resource mapping, scheduling, and execution of processes. All the mockups and workflows are converted into pieces for development. We select features as per the priority and start developing upon the language required and agreed on. Monitor the progress continuously through the development period."}
                        goal={"Goal"}
                        goalDescrition={"Plan, Execute, Monitor"}
                    />
                    <Description
                        title={"Staging Release"}
                        description={"We enable a staging environment for the initial release to make sure the application is coming up as we planned. Here we test and rigorously roll out features so that the application is tested with all criterias, bug -free and make sure we acheive the business needs. And make the application Production ready."}
                        goal={"Goal"}
                        goalDescrition={"Testing, Playground to evaluate all the scenarios for a robust application"}
                    />
                    <Description
                        title={"CI/CD With Testing"}
                        description={"At Progton Technologies, CI/CD pipeline is in practice to build efficient- application while reducing risk and time in the lifecycle of software development."}
                        goal={"Goal"}
                        goalDescrition={"Minimise Bugs, Robust testing, Feature Integrations."}
                    />
                    <Description
                        title={"Quality Assurance"}
                        description={"Our Quality Assurance covers the entire process of the development cycle with both Functional testing and Non Functional testing to make sure that a product has met its specification in the topmost quality possible along with the business requirements of the client."}
                        goal={"Goal"}
                        goalDescrition={"To deliver bug-free application"}
                    />
                    <Description
                        title={"Going Live & Monitoring"}
                        description={"At Progton Technologies, we ensure that before the application is published, a load test and Performance test is performed on all parts of your environment to ensure that the current architecture setup meets the metrics."}
                        goal={"Goal"}
                        goalDescrition={"Production Release & Monitoring"}
                    />
                    <Description
                        title={"Maintenance & Security Monitoring"}
                        description={"Our periodic checkups of all systems and networking devices ensure that appropriate logs are being aggregated to a central log management system for analysis and review."}
                        goal={"Goal"}
                        goalDescrition={"Monitoring, Log Management"}
                    />
                    <Description
                        title={"Support, Enhancements & Optimizations"}
                        description={"Formal handoff of the project back to key stakeholders, with agreed ongoing support and planned re-evaluations to ensure successful delivery & optimization of the solution offered."}
                        goal={"Goal"}
                        goalDescrition={"Delivery, HandOffs, Handover, Restart"}
                    />
                </div>
            </div>
        </div>
    )

}

export default Requirement