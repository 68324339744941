import React, { useState, useEffect, useRef } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./NavBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Container } from 'react-bootstrap';
import MassitecLogo from '../../../assets/Massitec Brand.png';

const NavBar = ({ defaultBgColor = 'transparent' }) => {
  const [isCompanyDropdownVisible, setCompanyDropdownVisible] = useState(false);
  const [isServiceDropdownVisible, setServiceDropdownVisible] = useState(false);
  const collapseRef = useRef(null);
  const [navSize, setnavSize] = useState("10rem");
  const [navColor, setnavColor] = useState(defaultBgColor);

  const handleMouseEnterCompany = () => {
    setCompanyDropdownVisible(true);
  };

  const handleMouseLeaveCompany = () => {
    setCompanyDropdownVisible(false);
  };

  const handleMouseEnterService = () => {
    setServiceDropdownVisible(true);
  };

  const handleMouseLeaveService = () => {
    setServiceDropdownVisible(false);
  };

  const listenScrollEvent = () => {
    window.scrollY > 80 ? setnavColor("#024959") : setnavColor(defaultBgColor);
    window.scrollY > 70 ? setnavSize("7rem") : setnavSize("8rem");

    const scrollTop = window.scrollY;
    if (collapseRef.current) {
      collapseRef.current.style.backgroundColor =
        scrollTop > 80 ? '#024959' : defaultBgColor;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <nav
      style={{
        backgroundColor: navColor,
        height: navSize,
        transition: "all 1s",
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 999
      }}
    >
      <Navbar expand="lg" className="gap-3 px-3" variant="dark">
        <Container>
          <Navbar.Brand
            href="/"
            className="mx-auto"
            style={{ paddingRight: "15rem", color: "#fff" }}
          >
            <img src={MassitecLogo} width={100} height={100} alt="MassiTec" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse ref={collapseRef}>
            <Nav className="flex-grow-1 justify-content-between" style={{ paddingLeft: '1rem' }}>
              <Nav.Item>
                <Nav.Link
                  style={{ paddingRight: '2rem', color: '#fff' }}
                  href="/"
                >
                  Home
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{ paddingRight: '2rem', color: '#fff' }}
                  href="/about"
                >
                  About
                </Nav.Link>
              </Nav.Item>

              {/* Company  */}
              {/* <NavDropdown
                title={<span className="dropdown-title">Company</span>}
                id="basic-nav-dropdown"
                onMouseEnter={handleMouseEnterCompany}
                onMouseLeave={handleMouseLeaveCompany}
                show={isCompanyDropdownVisible}
                style={{ marginRight: '2rem' }}
              >
                <NavDropdown.Item href="/about">About US</NavDropdown.Item>
                <NavDropdown.Item href="/work-methodology">
                  Work Methodology
                </NavDropdown.Item>
                <NavDropdown.Item href="/why-us">
                  Why Choose US
                </NavDropdown.Item>
              </NavDropdown> */}

              {/* Services  */}
              <NavDropdown
                title={<span className="dropdown-title">Services</span>}
                id="basic-nav-dropdown"
                onMouseEnter={handleMouseEnterService}
                onMouseLeave={handleMouseLeaveService}
                show={isServiceDropdownVisible}
                style={{ marginRight: '2rem' }}
              >
                <NavDropdown.Item href="/software">
                  Software Development
                </NavDropdown.Item>
                <NavDropdown.Item href="/uiux">
                  UI UX & Graphic Design
                </NavDropdown.Item>
                <NavDropdown.Item href="/web">
                  Web Consultancy
                </NavDropdown.Item>
                <NavDropdown.Item href="/network">
                  Network & Windows Server
                </NavDropdown.Item>
                <NavDropdown.Item href="/mobile">Mobile App</NavDropdown.Item>
              </NavDropdown>
              <Nav.Item style={{ marginRight: '2rem' }}>
                <Nav.Link href="/contact" style={{ color: '#fff' }}>
                  Contact
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/blog" style={{ color: '#fff' }}>
                  Blog
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end search-section">
            <div className="v-line"></div>
            <Navbar.Text className="phone-icon-container">
              <FontAwesomeIcon icon={faPhone} className="phone-icon" />
              <div className="phone-info">
                <span className="question">Have any question?</span>
                <span className="info-number">+223 0553766779</span>
              </div>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </nav>
  );
};


export default NavBar;
