import React from 'react'
import './NetworkService.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import NavBar from '../header/navbar/NavBar'
import { FaClipboardList, FaCog, FaShieldAlt, FaHeadset, FaChartLine } from 'react-icons/fa'; // Importing icons


import Network1 from '../../assets/network1.png'
import Network2 from '../../assets/network2.png'
import Network3 from '../../assets/network3.png'
import Network4 from '../../assets/network4.png'
import Network5 from '../../assets/network5.png'
import Network6 from '../../assets/network6.png'
import Network7 from '../../assets/network7.png'
import Network8 from '../../assets/network8.png'
import Footer from '../footer/Footer'
import Contact from '../contact/Contact'
import { useNavigate } from 'react-router-dom'


const NetworkService = () => {
    const cards = [
        {
            title: "Assessment and Planning",
            icon: <FaClipboardList />, // Assessment icon
            description:
                "We begin by assessing your current network and Windows environment, identifying areas for improvement and planning a strategy that aligns with your business goals.",
        },
        {
            title: "Implementation and Configuration",
            icon: <FaCog />, // Implementation icon
            description:
                "Once the plan is in place, we implement and configure your network and Windows systems, ensuring they are optimized for performance, security, and reliability.",
        },
        {
            title: "Monitoring and Maintenance",
            icon: <FaChartLine />, // Monitoring icon
            description:
                "We continuously monitor your IT infrastructure, performing regular maintenance and updates to keep your systems running smoothly and securely.",
        },
        {
            title: "Security Management",
            icon: <FaShieldAlt />, // Security icon
            description:
                "We implement and manage robust security measures to protect your network and data from threats, ensuring your business remains safe and compliant.",
        },
        {
            title: "Support and Optimization",
            icon: <FaHeadset />, // Support icon
            description:
                "Our team is always on hand to provide support and optimize your systems as your business grows and evolves. We ensure your IT infrastructure adapts to meet your changing needs.",
        },
    ];

    const navigate = useNavigate();
    const contactPage = () => {
        navigate('/contact');
    }
    const networkImages = [
        Network5, Network6, Network7
    ]
    return (
        <div>
            <div className="network-content">
                <div className="network-title">
                    <h1>Network & Windows Server</h1>
                    <h6>
                        {" "}
                        <span className="design-style">Home</span>{" "}
                        <span className="choose-icon">
                            <FontAwesomeIcon icon={faCaretRight} size="1x" />
                        </span>{" "}
                        Network & Windows Server{" "}
                    </h6>
                </div>
                <div className='network-nav'>
                    <NavBar />
                </div>
            </div>
            <div className='network-content-description'>
                <div style={{ textAlign: 'center', width: '80%', margin: '0 auto' }}>
                    <h2 className='networ-heading'>Network and Windows Services</h2>
                    <p className='networ-heading' style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        Empower Your Business with Reliable Network and Windows Solutions
                    </p>
                    <p>
                        Your IT infrastructure is the backbone of your business. It supports your operations, connects your teams, and ensures your data is secure. At Massitec, we understand the critical role that reliable network and Windows services play in your success. That’s why we offer comprehensive solutions designed to keep your systems running smoothly, efficiently, and securely.
                    </p>
                </div>
                <h2 className='networ-heading text-center mt-5'> Our Network and Windows Services </h2>
                <div className='network-content-main'>
                    <div className='network-content-main-left'>
                        <div className='network-content-main-left-header'>
                            <img src={Network1} alt='Network' width={70} />
                            <h3 className='network-content-header-tag networ-heading'>Network Assessment</h3>
                        </div>
                        <p className='network-content-main-p'>
                            Our network assessment service is the foundation of an optimized IT infrastructure. We conduct a detailed analysis of your current network setup, identifying weaknesses, security vulnerabilities, and performance issues. Based on this assessment, we provide recommendations and strategies to improve network performance and security.
                        </p>

                        <div className='network-content-main-left-header'>
                            <img src={Network2} alt='Network' width={70} />
                            <h3 className='network-content-header-tag networ-heading'>Windows Server</h3>
                        </div>
                        <p className='network-content-main-p'>
                            Windows Server is a critical component of your IT environment. We offer comprehensive Windows Server solutions, including installation, configuration, and management services. Our team ensures your Windows Server is optimized for performance, security, and reliability, helping you achieve seamless operations and data protection.
                        </p>
                    </div>
                    <div className='network-content-main-right'>
                        <div className='network-content-main-left-header'>
                            <img src={Network3} alt='Network' width={70} />
                            <h3 className='network-content-header-tag networ-heading'>LAN WAN Optimization</h3>
                        </div>
                        <p className='network-content-main-p'>
                            Efficient data transmission is essential for seamless business operations. We optimize both your LAN and WAN to ensure fast, secure, and reliable connectivity across all locations. Our services include traffic management, bandwidth optimization, and latency reduction, helping you achieve a more responsive and robust network.
                        </p>

                        <div className='network-content-main-left-header'>
                            <img src={Network4} alt='Network' width={70} />
                            <h3 className='network-content-header-tag networ-heading'>Application Delivery Controllers ADC</h3>
                        </div>
                        <p className='network-content-main-p'>
                            The performance of your applications can directly impact your business success. We implement and manage ADCs that enhance the delivery of your applications, ensuring they are fast, secure, and always available. Our ADC solutions help balance loads, protect against attacks, and provide a better user experience.
                        </p>
                    </div>
                </div>
            </div>
            {/* <div style={{ width: '55%', margin: '0 auto', marginTop: '10rem' }}>
                <h2 className='networ-heading'>Our Approach to IT Management</h2>
                <div style={{ textAlign: 'justify', marginTop: '1rem' }}>
                    <p>
                        <sapn className='networ-heading' style={{ fontWeight: 'bold' }}>Assessment and Planning: </sapn>
                        We begin by assessing your current network and Windows environment, identifying areas for improvement and planning a strategy that aligns with your business goals.
                    </p>
                </div>
                <div style={{ textAlign: 'justify', marginTop: '1rem' }}>
                    <p>
                        <sapn className='networ-heading' style={{ fontWeight: 'bold' }}>Implementation and Configuration: </sapn>
                        Once the plan is in place, we implement and configure your network and Windows systems, ensuring they are optimized for performance, security, and reliability.
                    </p>
                </div>
                <div style={{ textAlign: 'justify', marginTop: '1rem' }}>
                    <p>
                        <sapn className='networ-heading' style={{ fontWeight: 'bold' }}>Monitoring and Maintenance: </sapn>
                        We continuously monitor your IT infrastructure, performing regular maintenance and updates to keep your systems running smoothly and securely.
                    </p>
                </div>
                <div style={{ textAlign: 'justify', marginTop: '1rem' }}>
                    <p>
                        <sapn className='networ-heading' style={{ fontWeight: 'bold' }}>Security Management: </sapn>
                        We implement and manage robust security measures to protect your network and data from threats, ensuring your business remains safe and compliant.
                    </p>
                </div>
                <div style={{ textAlign: 'justify', marginTop: '1rem' }}>
                    <p>
                        <sapn className='networ-heading' style={{ fontWeight: 'bold' }}>Support and Optimization: </sapn>
                        Our team is always on hand to provide support and optimize your systems as your business grows and evolves. We ensure your IT infrastructure adapts to meet your changing needs.
                    </p>
                </div>
            </div> */}
            <div className="network-section">
                <h2 className="networ-heading">Our Approach to IT Management</h2>
                <div className="network-cards">
                    {cards.map((card, index) => (
                        <div className="network-card" key={index}>
                            <div className="card-icon">{card.icon}</div>
                            <h3 className="card-title">{card.title}</h3>
                            <p className="card-description">{card.description}</p>
                        </div>
                    ))}
                </div>
            </div>



            {/* <div className='network-content-managed'>
                <div>
                    <h2>MANAGED NETWORK SERVICES</h2>
                    <p>Location And Remediation Outages And Bottlenecks
                        That Threaten Business-Critical Applications
                    </p>
                </div>
                <div className='network-content-managed-component'>
                    <div className='network-content-service'>
                        <div className='network-roadmap'>
                            <h5>RoadMap</h5>
                            <p className='network-roadmap-p'>
                                Lorem ipsum dolor sit amet consectetur. Dolor ut vel massa enim facilisis scelerisque risus at. Blandit arcu volutpat et nibh sodales fermentum porta.
                                Feugiat facilisi nulla vestibulum urna nam nullam. Mollis sed at commodo viverra nulla.
                            </p>
                        </div>
                    </div>
                    <div className='network-content-service'>
                        <div className='network-roadmap'>
                            <h5>Benefits</h5>
                            <p className='network-roadmap-p'>
                                Lorem ipsum dolor sit amet consectetur. Dolor ut vel massa enim facilisis scelerisque risus at. Blandit arcu volutpat et nibh sodales fermentum porta.
                                Feugiat facilisi nulla vestibulum urna nam nullam. Mollis sed at commodo viverra nulla.
                            </p>
                        </div>
                    </div>
                    <div className='network-content-service'>
                        <div className='network-roadmap'>
                            <h5>Hierarchy</h5>
                            <p className='network-roadmap-p'>
                                Lorem ipsum dolor sit amet consectetur. Dolor ut vel massa enim facilisis scelerisque risus at. Blandit arcu volutpat et nibh sodales fermentum porta.
                                Feugiat facilisi nulla vestibulum urna nam nullam. Mollis sed at commodo viverra nulla.
                            </p>
                        </div>
                    </div>
                </div>
                <p>
                    Stop Wasting time and Money on technology.
                    <span className='network-content-getstarted'>
                        <a href='#some-section' onClick={contactPage}>let's get started</a>
                    </span>
                </p>
            </div> */}

            {/* <div className='network-content-delivery'>
                <h2 className='network-content-delivery-h2'>Delivering value-added IT services by focusing on 3 key areas</h2>
                <div className='network-content-delivery-main hide-mobile'>
                    {networkImages.map((image, index) => (
                        <div key={index}>
                            <img src={image} alt='NetworkService' width={150} />
                        </div>
                    ))}
                </div>

                <div className='network-content-delivery-main'>
                    <div className='network-content-delivery-desc'>
                        <div className='network-content-service-delivery'>
                            <span className='network-content-delivery-num'><h5>1</h5></span>
                            <h3>Technology</h3>
                        </div>
                        <p className='network-content-delivery-content'>Lorem ipsum dolor lorem ipsum dolor ipsum dolor lorem ipsum dolor ipsum dolor lorem ipsum dolor lorem ipsum dolor ipsum dolor lorem ipsum dolor lorem ipsum dolor ipsum dolor lorem ipsum dolor</p>
                    </div>
                    <div className='network-content-delivery-desc'>
                        <div className='network-content-service-delivery'>
                            <span className='network-content-delivery-num'><h5>2</h5></span>
                            <h3>Process</h3>
                        </div>
                        <p className='network-content-delivery-content'>Lorem ipsum dolor lorem ipsum dolor ipsum dolor lorem ipsum dolor ipsum dolor lorem ipsum dolor lorem ipsum dolor ipsum dolor lorem ipsum dolor lorem ipsum dolor ipsum dolor lorem ipsum dolor</p>
                    </div>
                    <div className='network-content-delivery-desc'>
                        <div className='network-content-service-delivery'>
                            <span className='network-content-delivery-num'><h5>3</h5></span>
                            <h3>People</h3>
                        </div>
                        <p className='network-content-delivery-content'>Lorem ipsum dolor lorem ipsum dolor ipsum dolor lorem ipsum dolor ipsum dolor lorem ipsum dolor lorem ipsum dolor ipsum dolor lorem ipsum dolor lorem ipsum dolor ipsum dolor lorem ipsum dolor</p>
                    </div>

                </div>
            </div> */}

            {/* <div className='network-service-structure'>
                <div className='network-service-structure-left'>
                    <p className='network-service-structure-heading'>The cost of discontinuity</p>
                    <p className='network-service-structure-title'>
                        Lorem ipsum dolor sit amet consectetur. Dolor ut vel massa enim facilisis scelerisque risus at. Blandit arcu volutpat et nibh sodales fermentum porta.
                        Feugiat facilisi nulla leo laoreet egestas vestibulum urna nam nullam. Mollis sed at commodo viverra nulla.
                    </p>
                    <div className='network-service-structure-figures-main'>
                        <div className='network-service-structure-figures'>
                            <span className='network-service-percentage'>50%</span>
                            <p>Lorem ipsum dolor lorem ipsum dolor</p>
                        </div>
                        <div className='network-service-structure-figures'>
                            <span className='network-service-percentage'>100%</span>
                            <p>Lorem ipsum dolor lorem ipsum dolor</p>
                        </div>
                        <div className='network-service-structure-figures'>
                            <span className='network-service-percentage'>500%</span>
                            <p>Lorem ipsum dolor lorem ipsum dolor</p>
                        </div>
                    </div>
                </div>
                <div className='network-service-structure-right'>
                    <img src={Network8} alt='NetworkService' width={500} height={325} />
                </div>
            </div> */}
            <div className='network-service-structure-contact'>
                <Contact h3={"Ensure Smooth Operations with Reliable IT Services."}
                    h5={"Contact us today to learn how we can support your IT needs!"}
                />
            </div>

            <Footer />
        </div>
    )
}

export default NetworkService