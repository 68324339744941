import React from 'react'
import './Contact.css'
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/free-regular-svg-icons'
import { useNavigate } from 'react-router-dom';


const Contact = ({ h3, h5 }) => {
  const navigate = useNavigate();
  const createMarkup = (text) => ({ __html: text });

  const contactPage = () => {
    navigate('/contact');
  }

  return (
    <div className='contact-main'>
      <div className='contact-left'>
        <h3 dangerouslySetInnerHTML={createMarkup(h3)} />
        <h5 dangerouslySetInnerHTML={createMarkup(h5)} />
      </div>
      <div className='contact-right'>
        <Button variant='primary' href='#' onClick={contactPage}>
          <FontAwesomeIcon icon={faMessage} size='lg' style={{ marginRight: '1rem' }} />
            Get Started
        </Button>
      </div>
    </div>
  )
}

export default Contact