import React, { useEffect, useState } from "react";
import NavBar from "./navbar/NavBar";
import "./Header.css";
import Card from "./card/CardHeader";
import {
  faLaptopCode,
  faRobot,
  faNetworkWired,
  faBezierCurve,
  faMobileScreen,
  faGears,
} from "@fortawesome/free-solid-svg-icons";


const Header = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const cardsData = [
    { icon: faLaptopCode, name: "Software Development" },
    { icon: faGears, name: "Web & Mobile Consultancy" },
    { icon: faBezierCurve, name: "UI UX & Graphic Design" },
    { icon: faNetworkWired, name: "Network & Windows Server" },
    { icon: faMobileScreen, name: "Mobile App" },
    { icon: faRobot, name: "Automation & Robotics" },
  ];

  const displayedCards = isMobile ? cardsData.slice(0, 0) : cardsData;

  return (
    <div className="HeaderMain">
      <NavBar />
      <div className="cardList">
        {displayedCards.map((card, index) => (
          <Card key={index} icon={card.icon} name={card.name} />
        ))}
      </div>
    </div>
  );
};

export default Header;
