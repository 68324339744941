import React from 'react'
import './Description.css'


const Description = ({title, description, goal, goalDescrition}) => {
  return (
    <div className='requirement-description'>
        <h5>{title}</h5>
        <p>{description}</p>
        <p><span className='requirement-goal'>{goal}:</span> {goalDescrition}</p>
    </div>
  )
}

export default Description