import React from 'react'
import './SoftwareDevService.css'
import NavBar from '../header/navbar/NavBar'
import AfricaMap from '../../assets/Africa 3.png'
import { FaTools, FaProjectDiagram, FaLayerGroup, FaShieldAlt, FaHandsHelping } from 'react-icons/fa';
import SoftwareLifecycle from '../../assets/software-development cycle.png'
import Contact from '../contact/Contact'
import Footer from '../footer/Footer'


const SoftwareDevService = () => {
    return (
        <div className='dev-service-main'>
            <NavBar />
            <div className='dev-services'>
                <div className='dev-services-head'>
                    <h2>Create
                        <sapn className="dev-services-title"> beautiful, fast, and secure web applications tailored exclusively
                            <br />for your business goals.</sapn>
                    </h2>
                    <p>
                        Our personalized software solutions are designed to resolve specific business challenges
                        and produce meaningful outcomes, from easy customizations to full-cycle software growth.
                        We are a leading web application development company for custom web solutions.
                        We are experts in Web development, Bespoke CMS, CRM, ERP Development, & Responsive Web
                        Apps.
                    </p>
                </div>
            </div>
            <section className="business-model">
                <h2 className="title">Our Business Model</h2>
                <div className="content-container">
                    <div className="map-container">
                        {/* You can use an image of Africa and add arrows over it */}
                        <img src={AfricaMap} alt="Africa Map" sizes={100} />
                    </div>

                    <div className="description-container">
                        <div className="description-box">
                            <h3>Discovery Phase</h3>
                            <p>
                                We begin by gaining a deep understanding of your business needs and goals, identifying the challenges you face and the opportunities for improvement.
                            </p>
                            <div className="step-number">1</div>
                        </div>
                        <div className="description-box">
                            <h3>Development</h3>
                            <p>Our skilled developers then bring the designs to life, coding with precision and care. We follow best practices to ensure your software is robust, scalable, and secure.</p>
                            <div className="step-number">2</div>
                        </div>
                        <div className="description-box">
                            <h3>Testing and Quality Assurance</h3>
                            <p>Before launch, we rigorously test the software to catch any bugs or issues. Our QA process ensures that the final product is of the highest quality.</p>
                            <div className="step-number">3</div>
                        </div>
                        <div className="description-box">
                            <h3>Deployment and Training</h3>
                            <p>Once the software is ready, we handle the deployment process, ensuring a smooth transition. We also provide training to your team, empowering them to make the most of the new system.</p>
                            <div className="step-number">4</div>
                        </div>
                        <div className="description-box">
                            <h3>Post-Launch Support</h3>
                            <p>After the software is live, we remain by your side, offering support and updates as needed.</p>
                            <div className="step-number">5</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-choose-section" style={{marginTop: '8rem', marginBottom: '4rem'}}>
                <div className="left-column">
                    <h2 className="title">Why Choose Massitec for Your Software Development Needs?</h2>
                    <img src={SoftwareLifecycle} alt="Software Development" />
                </div>
                <div className="right-column">
                    <div className="feature-box">
                        <span className="feature-icon">
                            <FaProjectDiagram />
                        </span>
                        <div>
                            <h3>End-to-End Development</h3>
                            <p>From conceptualization to deployment, we manage the entire software development lifecycle. Our process is transparent, collaborative, and geared towards delivering a product that exceeds expectations.</p>
                        </div>
                    </div>

                    <div className="feature-box">
                        <span className="feature-icon">
                            <FaLayerGroup />
                        </span>
                        <div>
                            <h3>Scalability and Flexibility</h3>
                            <p>Our solutions are built with growth in mind. As your business evolves, your software can adapt, ensuring it remains relevant and effective.</p>
                        </div>
                    </div>

                    <div className="feature-box">
                        <span className="feature-icon">
                            <FaTools />
                        </span>
                        <div>
                            <h3>Monitoring and Maintenance</h3>
                            <p>We continuously monitor your IT infrastructure, performing regular maintenance and updates to keep your systems running smoothly and securely.</p>
                        </div>
                    </div>

                    <div className="feature-box">
                        <span className="feature-icon">
                            <FaShieldAlt />
                        </span>
                        <div>
                            <h3>Security First</h3>
                            <p>In an era where data breaches are all too common, we prioritize security at every stage of development, protecting your business from potential threats.</p>
                        </div>
                    </div>

                    <div className="feature-box">
                        <span className="feature-icon">
                            <FaHandsHelping />
                        </span>
                        <div>
                            <h3>Continuous Support</h3>
                            <p>Our commitment to you doesn’t end with delivery. We offer ongoing support and maintenance to ensure your software continues to perform optimally.</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className='dev-tech-index'>
                {/* <TechnologyIndex /> */}
                <Contact h3={"Great ideas are converted into Great Apps by <br/> Great People"}
                    h5={"Call us now for free Product Discovery <br/> Workshop"} />
            </div>
            <Footer />
        </div>
    )
}

export default SoftwareDevService