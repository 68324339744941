import React from "react";
import "./Work-methodology.css";
import Image1 from "../../assets/progton-icon-01.png";
import Image2 from "../../assets/progton-icon-02.png";
import Image3 from "../../assets/progton-icon-03.png";
import Image4 from "../../assets/progton-icon-04.png";
import Image5 from "../../assets/progton-icon-05.png";
import Image6 from "../../assets/progton-icon-06.png";
import Image7 from '../../assets/progton-icon-07.png'
import Image8 from '../../assets/progton-icon-08.png'
import Image9 from '../../assets/progton-icon-09.png'
import Image10 from '../../assets/progton-icon-10.png'
import Methodology from "./methodology/Methodology"
import NavBar from "../header/navbar/NavBar";
import Requirement from "../requirement/Requirement";
import Footer from '../footer/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'


const WorkMethodology = () => {

  const serviceData = [
    {
      img: Image1,
      alt: "Requirement",
      title: "Requirement Analysis",
    },
    {
      img: Image2,
      alt: "Design",
      title: "Designing",
    },
    {
      img: Image3,
      alt: "Architecture",
      title: "Architecture",
    },
    {
      img: Image4,
      alt: "development",
      title: "Development",
    },
    {
      img: Image5,
      alt: "staggin",
      title: "Staging Release",
    },
    {
      img: Image6,
      alt: "support",
      title: "Support & Optimization",
    },
    {
      img: Image7,
      alt: "maintenace",
      title: "Maintenance & Security Monitoring",
    },
    {
      img: Image8,
      alt: "live",
      title: "Going Live & Monitoring",
    },
    {
      img: Image9,
      alt: "quality",
      title: "Quality Assurance",
    },
    {
      img: Image10,
      alt: "ci/cd",
      title: "CI/CD With Testing",
    },
  ];

  const limitedServiceData = serviceData.slice(0, 5);

  return (
    <div className="method-content">
      <div className="method-content-main">
        <div className="method-content-title">
          <h1>Methodology</h1>
          <h6>
            {" "}
            <span className="method-style">Home</span>{" "}
            <span className="method-icon">
              <FontAwesomeIcon icon={faCaretRight} size="1x" />
            </span>{" "}
            Methodology{" "}
          </h6>
        </div>
        <NavBar />
      </div>

      <div className="method-nav" />
      <h2>
        Work <span className="mothod-title">Methodology</span>
      </h2>
      <div className="work-methodology">
        {limitedServiceData.map((data, index) => (
          <Methodology
            key={index}
            img={data.img}
            alt={data.alt}
            title={data.title}
            showArrow={index < 4}
          />
        ))}
      </div>
      {/** Add a new row for the remaining items */}
      {serviceData.length > 5 && (
        <div className="work-methodology reverse-arrow">
          {serviceData.slice(5).map((data, index) => (
            <div className="methodology-item" key={index}>
              <Methodology
                img={data.img}
                alt={data.alt}
                title={data.title}
                showArrow={index < 4}
              />
            </div>
          ))}
        </div>
      )}

      <Requirement />
      <Footer />
    </div>
  );
};

export default WorkMethodology;
