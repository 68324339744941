import React from 'react'
import './OurPartner.css'
import Image from '../../assets/partner.png'
import { Button } from 'react-bootstrap'


const OurPartner = () => {
  return (
    <div className='partner-main'>
      <div className='partern-content'>
        <div className='partner-text'>
          <h5>Experience, Execution, Excellence</h5>
          <h2>Your partner for <br /> Software Inovation</h2>
          <p>
            Empower your business with cutting-edge solutions and elevate your digital presence with MassiTec—the trusted partner for software innovation. We specialize in crafting secure and efficient IT infrastructures, delivering custom software development, product design, QA, and consultancy services. Experience unparalleled expertise as we help you maintain, modernize, and scale your IT infrastructure for seamless business growth.
          </p>
          <Button href='/about'>LERN MORE</Button>
        </div>
        <div className='partner-image'>
          <img src={Image} width={500} height={440} alt='PartnerImage' />
        </div>
      </div>
    </div>
  )
}

export default OurPartner
