import React from "react";
import "./Methology.css";


const Methodology = ({ img, alt, title, showArrow}) => {
  return (
    <div className="methodoly-main">
      <div className="method-content">
        <img src={img} alt={alt} width={60} height={60} />
        <h6>
          {title.split(' & ').map((word, index, array) => (
            <span key={index}>
              {word}
              {index === 0 && array.length > 1 && <br />}
            </span>
          ))}
        </h6>
      </div>
      {showArrow && <div class="dashed-arrow extra-arrow" /> }
    </div>
  );
};

export default Methodology;
