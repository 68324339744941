import React from "react";
import ServiceCard from "../service-card/ServiceCard";
import "./Content.css";
import Image1 from "../../assets/Software Development.png";
import Image2 from "../../assets/UI UX design.png";
import Image3 from "../../assets/Consultation.png";
import Image4 from "../../assets/servers.png";
import Image5 from "../../assets/Mobile and Web.png";
import Image6 from "../../assets/Automation.png";
import OurPartner from "../partner/OurPartner";
import TrustedCountry from "../trusted-countries/TrustedCountry";
import TrustMap from "../trusted-countries/trust-map/TrustMap";
import Innovate from "../innovate/Innovate";
import Footer from "../footer/Footer";
import Contact from "../contact/Contact";


const Content = () => {
  const serviceData = [
    {
      img: Image1,
      alt: "Software",
      title: "Software Development",
      description:
        "Creating secure, responsive web and mobile-friendly applications tailored specially for your business.",
      href: "/software"
    },
    {
      img: Image2,
      alt: "UIUX",
      title: "UI UX & Graphic Design",
      description:
        "Enhance your online visibility through our UI/UX design services, merging innovation and creativity to create designs that align with your brand and elevate user engagement.",
      href: "/uiux"
    },
    {
      img: Image3,
      alt: "Consultancy",
      title: "Web Consultancy",
      description:
        "We provide strategic insights and tailored guidance for startups and established businesses, empowering you to make informed decisions and achieve sustained digital growth.",
      href: "/web"
    },
    {
      img: Image4,
      alt: "Server",
      title: "NetWorking & Windows Server",
      description:
        "We specialize in crafting secure and efficient IT infrastructures, offering seamless network configurations and robust server solutions tailored to your unique requirements.",
      href: "/network"
    },
    {
      img: Image5,
      alt: "Mobile",
      title: "Mobile App",
      description:
        "Be it small businesses or large Enterprises; B2B or B2C, We design & build optimized &  results-driven custom mobile applications using cutting edge technologies for all mobile platforms.",
      href: "/mobile"
    },
    {
      img: Image6,
      alt: "Automation",
      title: "Automation & Robotics",
      description:
        "Step into the future of business excellence with our Automation & Robotics service. Explore cutting-edge innovation as we provide bespoke solutions, revolutionizing your processes and boosting operational efficiency.",
      href: '#'
    },
  ];

  return (
    <div className="service-main">
      <div className="service-container">
        <h1 className="success-text">
          Preparing for your success,
          <br /> we provide{" "}
          <span className="preparing-success">
            truly prominent IT solutions.
          </span>
        </h1>
        <div className="content-card">
          {serviceData.map((data, index) => (
            <ServiceCard
              key={index}
              img={data.img}
              alt={data.alt}
              title={data.title}
              description={data.description}
              href={data.href}
            />
          ))}
        </div>
      </div>
      <OurPartner />
      <TrustedCountry />
      {/* <TrustMap /> */}
      {/* <Innovate /> */}
      <Contact h3={"Great ideas are converted into Great Apps by <br/> Great People"}
        h5={"Call us now for free Product Discovery <br/> Workshop"} />
      <Footer />
    </div>
  );
};

export default Content;
