import React from 'react'
import './Blog.css'
import { SocialIcon } from 'react-social-icons';
import NavBar from '../header/navbar/NavBar';
import Footer from '../footer/Footer';
import { Link } from 'react-router-dom';

import BlogImage from "../../assets/blog/ai-blog.webp";
import ScalableWeb from "../../assets/blog/scalable_app.jpg";
import ElevateUXImage from "../../assets/blog/elevate-ux.png";


const Blog = () => {
    return (
        <div>
            <div style={{ backgroundColor: '#024959' }}>
                <NavBar defaultBgColor='#024959' />
            </div>
            <div className="bradcam_area bradcam_bg_1" style={{ marginTop: '7rem', display: 'flex', alignItems: 'center' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div style={{ textAlign: 'left' }}>
                                <h3>blog</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog_area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mb-5 mb-lg-0">
                            <div className="blog_left_sidebar">
                                <article className="blog_item">
                                    <div className="blog_item_img">
                                        <img className="card-img rounded-0" src={BlogImage} alt="" />
                                        <a href="#somelink" className="blog_item_date">
                                            <p style={{fontWeight: '900', fontSize: '2rem'}}>15</p>
                                            <p>Sep</p>
                                        </a>
                                    </div>

                                    <div className="blog_details" style={{ textAlign: 'left' }}>
                                        <a href="/ai-health-care">
                                            <h2>How Artificial Intelligence is Revolutionizing Healthcare</h2>
                                        </a>
                                        <p>
                                        In recent years, artificial intelligence (AI) has become a transformative force in healthcare, driving innovation across both patient care and operational management.
                                        </p>
                                    </div>
                                </article>

                                <article className="blog_item" style={{ textAlign: 'left' }}>
                                    <div className="blog_item_img">
                                        <img className="card-img rounded-0" src={ScalableWeb} alt="" />
                                        <a href="#somelink" className="blog_item_date">
                                            <p style={{fontWeight: '900', fontSize: '2rem'}}>15</p>
                                            <p>Jan</p>
                                        </a>
                                    </div>

                                    <div className="blog_details">
                                        <a className="d-inline-block" href="/single-blog">
                                            <h2>Build a Scalable Web App for Business from Scratch: Challenges and Solutions</h2>
                                        </a>
                                        <p>
                                            In the digital era, businesses rely heavily on web applications to provide services, engage customers, and manage operations. As companies grow, so does the demand for scalable web applications that can handle increased traffic, larger datasets, and more complex functionalities.
                                        </p>
                                    </div>
                                </article>
                                <article className="blog_item" style={{ textAlign: 'left' }}>
                                    <div className="blog_item_img">
                                        <img className="card-img rounded-0" src={ElevateUXImage} alt="" />
                                        <a href="#somelink" className="blog_item_date">
                                            <p style={{fontWeight: '900', fontSize: '2rem'}}>17</p>
                                            <p>Sep</p>
                                        </a>
                                    </div>
                                    <div className="blog_details">
                                        <a className="d-inline-block" href="/elevate-ux">
                                            <h2>The Ultimate Guide to UI/UX Design: Elevating User Experience for Business Success.</h2>
                                        </a>
                                        <p>
                                            In today’s competitive digital landscape, a well-designed user interface (UI) and user experience (UX) are no longer just nice-to-haves—they're critical components of any successful product.
                                        </p>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog_right_sidebar">
                                <aside className="single_sidebar_widget post_category_widget">
                                    <h4 className="widget_title">Category</h4>
                                    <ul className="list cat-list" style={{textAlign: 'left'}}>
                                    <li>
                                    <a href="/ai-health-care" className="d-flex">
                                        <p>AI in Health Care</p>
                                    </a>
                                    </li>
                                        <li>
                                            <a href="/high-performant-website" >
                                                <p>Build a Scalable Web App for Business</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/elevate-ux">
                                                <p>The Ultimate Guide to UI/UX Design</p>
                                            </a>
                                        </li>
                                    </ul>
                                </aside>

                                <aside className="single_sidebar_widget popular_post_widget">
                                    <h3 className="widget_title">Recent Post</h3>
                                    <div
                                        className="media post_item"
                                        style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: "1rem",
                                        }}
                                    >
                                        <img src={BlogImage} alt="post" height={55} />
                                        <div className="media-body">
                                        <Link to="/#somelink">
                                            <h3>
                                                The Power of AI: How Artificial Intelligence is Revolutionizing Healthcare
                                            </h3>
                                        </Link>
                                        </div>
                                    </div>
                                    <div className='media post_item' style={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}>
                                        <img src={ScalableWeb} alt="post" height={73} />
                                        <div className="media-body">
                                        <Link to="/high-performant-website">
                                            <h3>
                                                Build a Scalable Web App for Business from Scratch: Challenges and Solutions.
                                            </h3>
                                        </Link>
                                        </div>
                                    </div>
                                    <div className='media post_item' style={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}>
                                        <img src={ElevateUXImage} alt="post" height={75} />
                                        <div className="media-body">
                                            <Link to="/elevate-ux">
                                                <h3>The Ultimate Guide to UI/UX Design: Elevating User Experience for Business Success.</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    {/* <div className='media post_item' style={{ display: 'flex', flexDirection: 'row' }}>
                                        <img src={post4} alt="post" />
                                        <div className="media-body">
                                            <Link to="/#somelink">
                                                <h3>Lorem ipsum dolor lorem ipsum dolor lorem ipsum dolor.</h3>
                                            </Link>
                                        </div>
                                    </div> */}
                                </aside>
                                <aside className="single_sidebar_widget instagram_feeds">
                                    <h4 className="widget_title">Social Media</h4>
                                    <SocialIcon url="https://twitter.com/" href='https://x.com/Massitecgh' target="_blank" style={{ marginRight: '8px', height: '35px', width: '35px'}}/>
                                    <SocialIcon url="https://www.facebook.com/" href='https://www.facebook.com/massitecgroup' target="_blank" style={{ marginRight: '8px', height: '35px', width: '35px' }} />
                                    <SocialIcon url="https://www.instagram.com/" href='https://www.instagram.com/massitecgh/' target="_blank" style={{ marginRight: '8px', height: '35px', width: '35px' }} />
                                    <SocialIcon url="https://www.linkedin.com/" href='https://www.linkedin.com/company/massitec-group/?viewAsMember=true' target="_blank" style={{ marginRight: '10px', height: '35px', width: '35px' }} />
                                    <SocialIcon url="https://www.youtube.com/" href='#someLinkl' target="_blank" style={{ height: '35px', width: '35px'}} />                    
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Blog