import React from 'react'
import './MobileContent.css'


const MobileContent = ({title, description, Image}) => {
  return (
    <div className='mobileContent'>
        <div>
            <h4 className='mobile-content-heading'>{title}</h4>
            <p className='mob-desc'>{description}</p>
        </div>
        <div className='mob-image'>
            <img src={Image} alt='Mobile' width={70}/>
        </div>
    </div>
  )
}

export default MobileContent