import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./CardHeader.css";


const Card = ({ icon, name }) => {
  return (
    <div className="cardContent">
      <div className="cardInner">
          <FontAwesomeIcon
          icon={icon}
          size="xl"
          style={{ color: "#43BAFF", marginBottom: "5px", marginTop: "1rem" }}
        />
        <p>{name}</p>
      </div>
    </div>
  );
};

export default Card;
