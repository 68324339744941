import React from "react";
import NavBar from "../header/navbar/NavBar";
import "./About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Image1 from "../../assets/about.jpg";
import Innovate from "../innovate/Innovate";
import Contact from "../contact/Contact";
import Footer from "../footer/Footer";
import Clients from "../clients/Clients";
import Image11 from "../../assets/progton-icon-01.png";
import Image2 from "../../assets/progton-icon-02.png";
import Image3 from "../../assets/progton-icon-03.png";
import Image4 from "../../assets/progton-icon-04.png";
import Image5 from "../../assets/progton-icon-05.png";
import Image6 from "../../assets/progton-icon-06.png";
import Image7 from '../../assets/progton-icon-07.png'
import Image8 from '../../assets/progton-icon-08.png'
import Image9 from '../../assets/progton-icon-09.png'
import Image10 from '../../assets/progton-icon-10.png'
import Methodology from "../work-methodology/methodology/Methodology";
import '../choose-us/WhyChooseUs.css'


const AboutUs = () => {
  const serviceData = [
    {
      img: Image11,
      alt: "Requirement",
      title: "Requirement Analysis",
    },
    {
      img: Image2,
      alt: "Design",
      title: "Designing",
    },
    {
      img: Image3,
      alt: "Architecture",
      title: "Architecture",
    },
    {
      img: Image4,
      alt: "development",
      title: "Development",
    },
    {
      img: Image5,
      alt: "staggin",
      title: "Staging Release",
    },
    {
      img: Image6,
      alt: "support",
      title: "Support & Optimization",
    },
    {
      img: Image7,
      alt: "maintenace",
      title: "Maintenance & Security Monitoring",
    },
    {
      img: Image8,
      alt: "live",
      title: "Going Live & Monitoring",
    },
    {
      img: Image9,
      alt: "quality",
      title: "Quality Assurance",
    },
    {
      img: Image10,
      alt: "ci/cd",
      title: "CI/CD With Testing",
    },
  ];

  const limitedServiceData = serviceData.slice(0, 5);

  return (
    <div>
      <div className="about-main">
        <div className="about-title">
          <h1>About US</h1>
          <h6>
            {" "}
            <span className="about-style">Home</span>{" "}
            <span className="about-icon">
              <FontAwesomeIcon icon={faCaretRight} size="1x" />
            </span>{" "}
            About{" "}
          </h6>
        </div>
        <NavBar />
      </div>
      <div className="about-content">
        <h2>We run all kinds of IT services that vow your success</h2>
        <div className="about-description">
          <div className="about-description-left">
            <img src={Image1} alt="AboutImage" width={400} height={250} />
          </div>
          <div className="about-description-right">
            <p>
              MasiTec Technologies is a software development company with 5+ years of experience in building billion-dollar enterprise software and applications with top developers having efficiency in proven technologies imbibing the industry-standard coding and security practices with ethics and integrity, fostering creativity and innovation making us one of the best software development company in the world.
            </p>
            <p>
              We pride ourselves on running a diverse range of IT services dedicated to ensuring your success. With a commitment to excellence, innovation, and client satisfaction, we offer comprehensive solutions that empower your business in the ever-evolving digital landscape. From software development and UI/UX design to web consultancy and networking solutions, our team is driven by the passion to elevate your business to new heights.
            </p>

            <p>
              {/* Lorem ipsum dolor sit amet consectetur. Posuere tristique
              pellentesque id dolor sed erat et vestibulum tellus. Faucibus
              donec accumsan consequat. Lorem ipsum dolor sit amet consectetur.
              Posuere tristique pellentesque id dolor sed erat et vestibulum
              tellus. Faucibus donec accumsan consequat. */}
            </p>
            {/* <span><a href="#some-section"><FontAwesomeIcon icon={faArrowRight} size="lg"
              className="dis-arrow" />LERAN MORE</a></span> */}
          </div>
        </div>
      </div>
      {/* <Innovate /> */}
      {/* <Clients /> */}
      <h2>
        Work <span className="mothod-title">Methodology</span>
      </h2>
      <div className="work-methodology mt-5">
        {limitedServiceData.map((data, index) => (
          <Methodology
            key={index}
            img={data.img}
            alt={data.alt}
            title={data.title}
            showArrow={index < 4}
          />
        ))}
      </div>
      {/** Add a new row for the remaining items */}
      {serviceData.length > 5 && (
        <div className="work-methodology reverse-arrow">
          {serviceData.slice(5).map((data, index) => (
            <div className="methodology-item" key={index}>
              <Methodology
                img={data.img}
                alt={data.alt}
                title={data.title}
                showArrow={index < 4}
              />
            </div>
          ))}
        </div>
      )}
       <div className="choose-us">
        <h1>
          Why Choose <span style={{ color: "#0d6efd" }}>Us.</span>
        </h1>
        <div className="choose-description">
          <div className="choose-description-left">
            <h1>
              Quality Digital <br />
              Products.
              <br />
              Delivered
            </h1>
          </div>
          <div className="choose-description-right">
            <h4>Client First</h4>
            <p>
              For any business, Clients are always at the core. Being a leading
              developer of web and mobile applications, our concept goes beyond
              our direct customers.We always start from where you are with your
              ideas and we think from the viewpoints of your end users, their
              areas of pain, and formulate a solution that solves key issues to
              help your business.
            </p>

            <h4>Effective Synergy</h4>
            <p>
              Communication is to hear what isn't being said. We appear at times
              to over-communicate. While it sounds overwhelming, it works
              wonders for our clients and our company ultimately. With
              communication alone we solve real-world problems. Our Development
              team remains in contact with customers through channels and media
              that allow performance, transparency and accuracy, feasible with
              all time zones across the World.
            </p>

            <h4>On Time Delivery</h4>
            <p>We do not over promise we over-deliver our promise. </p>
            <p>
              We have a great collection of internal frameworks with
              best-in-class infrastructure with a decade of experience in the IT
              software development industry that helps us to deliver solutions
              with superior quality at all times. We have learned how to avoid
              mistakes and repeat success all these years. We don't brag about
              consistency alone. In time, we describe and produce it. Our
              Products are here to notify the same.
            </p>

            <h4>Strong Expertise</h4>
            <p>
              With our core field of expertise being Ruby on Rails, Angular,
              React, our coding experts remain on the cutting edge of the newest
              and most successful technology trends and popular applications on
              the market. So you can expect a greater level of ownership,
              commitments & deliveries in less time with higher quality while
              following all the best practices for the development.
            </p>

            <h4>Total Ownership & Feedback Based</h4>
            <p>
              We do not follow the criteria or requirements just like that.
              Right from the beginning we have a direct influence on the
              project. We take spontaneous feedback from our clients, their
              customers, and other stakeholders that have an impact on the
              product. And we take your product and its feedback seriously. With
              this we make sure that we all are heading towards the right
              direction. Which saves really everything that matters.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="choose-card">
        {serviceData.map((data, index) => (
          <ServiceCard
            key={index}
            img={data.img}
            alt={data.alt}
            title={data.title}
            description={data.description}
            href={data.href}
          />
        ))}
      </div> */}
      <Contact h3={"We build & deliver Reliable & Quality"}
        h5={"Software Development Services."} />
      <Footer />

    </div>
  );
};

export default AboutUs;
